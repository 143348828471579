<!--
 * @Description: 采购商单据上传
 * @Author: zhang zhen
 * @Date: 2023-05-20 22:13:01
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-05-15 22:20:38
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/children/billUploader.vue
-->
<template>
  <div>
    <FileListView ref="FileListViewRef" />
  </div>
</template>

<script>
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import { postAction, getAction } from '@/api/manage'
import FileListView from './fileListView.vue'

const uidGenerator = () => {
  return '-' + parseInt(Math.random() * 10000 + 1, 10)
}
export default {
  name: 'BillUploader',
  components: {
    EmptyArea,
    FileListView
  },
  props: {
    orderNo: {
      type: String,
      default: ''
    },
    orderStatus: {
      type: [String, Number],
      default: '1'
    }
  },
  data() {
    return {
      info: {
        invoiceFile: null, // 发货单
        invoiceDate: null,
        signFile: null, // 收货单
        signDate: null
      },
      payTime: false,
      payFile: null,
      preTime: null,
      fapiaoFile: null,
      newOrderNo: null
    }
  },
  methods: {
    handleLoadTransportInfo(orderNo) {
      this.newOrderNo = orderNo
      getAction('/order/transport', {
        orderNo: this.orderNo || orderNo
      }).then(res => {
        const { success, message, data } = res
        if (success && data) {
          this.info = data
          const { payFile, fapiaoFile, invoiceFiles, signFiles } = data
          let fileList1 = invoiceFiles || [], fileList2 = signFiles || []
          console.log(fileList1, fileList2)
          let fileObject = {};
          if (fapiaoFile) {
            fileObject[fapiaoFile] = '预付款单据'
          }
          if (fileList1 && fileList1.length) {
            fileList1.forEach(i => {
              fileObject[i] = '发货单据'
            })
          }
          if (fileList2 && fileList2.length) {
            fileList2.forEach(i => {
              fileObject[i] = '收货单据'
            })
          }
          if (payFile) {
            fileObject[payFile] = '付款单信息'
          }
          this.info.invoiceFile && (fileObject[this.info.invoiceFile] = '发货单据')
          this.info.signFile && (fileObject[this.info.signFile] = '收货单据')
          this.$nextTick(_ => {
            this.$refs.FileListViewRef.handleGetFileList([fapiaoFile, ...fileList1, ...fileList2, payFile], fileObject)
          })

        }
      })

    },
    handleDownload(url) {
      if (!url) return this.$message.warning('没有单据信息')
      window.open(url)
    }
  }
}
</script>

<style lang="less" scoped>
.UploadProgress {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 24px 24px;

  &-item {
    width: 334px;
    height: 208px;
    background: #f7f8fa;
    border-radius: 8px;
    padding: 24px;
    display: flex;
    align-items: flex-start;

    img.cover {
      width: 200px;
      height: 160px;
      border-radius: 8px;
      margin-right: 14px;
    }

    .infos {
      .title {
        font-weight: 500;
        font-size: 16px;
        color: #222;
      }

      .time {
        color: #595959;
        font-size: 12px;
        margin-top: 4px;
      }

      .option {
        color: #ff6e2d;
        font-size: 12px;
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          margin-left: 5px;
        }
      }
    }
  }
}
</style>
