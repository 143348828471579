<!--
 * @Description: 采购商详情
 * @Author: zhang zhen
 * @Date: 2023-02-16 13:49:33
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-05-28 15:54:06
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/children/supplierInfoForUser.vue
-->
<template>
  <div class="supplierInfo view-content">
    <div class="order-info">
      <a-steps progress-dot :current="orderStatus == -1 ? 8 : orderStatus == 7 ? 8 : orderStatus == 9 ? 7 : orderStatus"
        size="small" v-if="orderProcess == '1'">
        <a-step :title="i" v-for="i in stepsList" :key="i" />
      </a-steps>

      <a-steps progress-dot
        :current="orderStatus == -1 ? 7 : orderStatus == 9 ? 6 : orderStatus >= 3 && orderStatus != 7 ? orderStatus - 1 : orderStatus"
        size="small" v-else>
        <a-step :title="i" v-for="(i, index) in stepsBList" :key="i" />
      </a-steps>
    </div>

    <h2 class="form-title">
      <span class="title">收货/发货信息</span>
    </h2>
    <a-tabs type="card">
      <a-tab-pane key="1" :tab="item.tabName" v-for="(item, index) in transportsVo" :key="index">
        <splitLine label="收货信息" />
        <div class="order_sport_info">
          <a-descriptions bordered :column="2" style="width: 820px;margin: 0;">
            <a-descriptions-item label="收货人">
              {{ item.deliveryName || '暂无' }}
            </a-descriptions-item>
            <a-descriptions-item label="期望收货时间">
              {{ item.expectDate || '暂无' }}
            </a-descriptions-item>
            <a-descriptions-item label="手机号">
              {{ item.phone || '暂无' }}
            </a-descriptions-item>
            <a-descriptions-item label="地址">
              {{ item.originalDetailAddress || '暂无' }}
            </a-descriptions-item>
          </a-descriptions>
          <a @click="handleChangeOrderAddress(item)" v-if="showTransports">修改收货地址</a>
        </div>
        <splitLine label="发货信息" />
        <div class="order_sport_info">
          <a-descriptions bordered :column="2" style="width: 820px;margin-top: 0;">
            <a-descriptions-item label="送货方式">
              {{ item.transportModeDictName || '暂无' }}
            </a-descriptions-item>
            <a-descriptions-item label="运单号">
              {{ item.waybill || '暂无' }}
            </a-descriptions-item>
            <a-descriptions-item label="发货时间">
              {{ item.deliveryDate || '暂无' }}
            </a-descriptions-item>
            <a-descriptions-item label="备注">
              {{ item.remark || '暂无' }}
            </a-descriptions-item>
          </a-descriptions>
        </div>
      </a-tab-pane>
    </a-tabs>


    <h2 class="form-title">
      <span class="title">付款/发票信息</span>
    </h2>
    <div data-v-34c89936="" class="ant-descriptions ant-descriptions-bordered">
      <div class="ant-descriptions-view">
        <table>
          <tbody>
            <tr class="ant-descriptions-row">
              <th class="ant-descriptions-item-label ant-descriptions-item-colon" rowspan="2">包材总额(元)</th>
              <td colspan="1" class="ant-descriptions-item-content" rowspan="2"> {{ payVo.goodsAmount || 0 }} </td>
              <th class="ant-descriptions-item-label ant-descriptions-item-colon">应付金额(元)</th>
              <td colspan="1" class="ant-descriptions-item-content"> {{ payVo.payables || 0 }} </td>
            </tr>
            <tr class="ant-descriptions-row">
              <!-- <th class="ant-descriptions-item-label ant-descriptions-item-colon">运费金额</th>
              <td colspan="1" class="ant-descriptions-item-content"> ￥0 </td> -->
              <th class="ant-descriptions-item-label ant-descriptions-item-colon">实付金额(元)</th>
              <td colspan="1" class="ant-descriptions-item-content"> {{ payVo.paid || 0 }} </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <ChangeShipping ref="ChangeShippingRef" @ok="handleLoadInfo" />
  </div>
</template>

<script>
import { getAction, putAction, postAction } from '@/api/manage'
import { mapGetters } from 'vuex'
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import transportsForm from './transportsForm.vue'
import splitLine from '@/components/plugins/splitLine.vue'
import payVoForm from './payVoForm.vue'
import uploadFile from '@/components/plugins/uploadFileSmall.vue'
import ChangeShipping from './changeShipping.vue'
export default {
  name: 'supplierInfo',
  components: {
    EmptyArea,
    transportsForm,
    payVoForm,
    uploadFile,
    splitLine,
    ChangeShipping
  },
  props: {
    orderNo: {
      type: String,
      default: ''
    },
    needEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      headerInfo: {},
      orderProcess: '1',
      editTransportFee: false,
      stepsList: ['签合同', '已签署', '已预付', '生产中', '已发货', '运输中', '已收货', '已收款', '已完结'],
      stepsBList: ['签合同', '已签署', '生产中', '已发货', '运输中', '已收货', '已收款', '已完结'],
      formItemLayout: {
        labelCol: { span: 11 },
        wrapperCol: { span: 13 }
      },
      loading: false,
      orderProductsVoList: [],
      payVo: {},
      transportsVo: {},
      orderStatus: 0,
      showTransports: false,
      dataSource: [

      ],
      total: 0,
      columns: [
        {
          title: '包装材料',
          dataIndex: 'wrapTypeDictName',
          width: 260
        },
        {
          title: '所需数量',
          dataIndex: 'num',
          width: 250
        },
        {
          title: '报价单价（元）',
          dataIndex: 'unitPrice',
          width: 280
        },
        {
          title: '报价合计（元）',
          dataIndex: 'totalPrice',
          // width: 220
        },
      ],
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleChangePrice(e, item) {
      if (item.count) {
        item.amount = e * item.count
      }
    },
    handleChangeCount(e, item) {
      if (item.price) {
        item.amount = e * item.price
      }
    },
    handleDeleteItem(index) {
      this.orderProductsVoList.splice(index, 1)
    },
    handleLoadInfo() {
      getAction('/order/detail', {
        orderNo: this.orderNo,
        userType: 1
      }).then(res => {
        const { success, data, message } = res
        if (success) {
          const { transportsVo, payVo, orderProductsVoList, orderProcess, status, supName, purName, quoteVo, showTransports } = data
          this.showTransports = showTransports || false;
          this.orderStatus = status
          this.orderProductsVoList = orderProductsVoList || []
          this.transportsVo = transportsVo || []
          this.payVo = payVo || {}
          this.orderProcess = orderProcess || '1'
          console.log(quoteVo)
          if (quoteVo) { // 存在报价
            const { businessId, quoteItemsVoList, totalAmount } = quoteVo
            this.headerInfo = {
              supName,
              purName,
              businessId,
            };
            this.dataSource = quoteItemsVoList
            this.total = totalAmount;
          }
        } else {
          this.$message.warning(message)
        }
      })
    },
    /* 运输方式编辑 */
    handleEditTransportInfo() {
      this.$refs.transportsForm.handleEdit({ ...this.transportsVo, objectId: this.orderNo })
    },
    /* 支付信息 */
    handleEditPayVoInfo() {
      this.$refs.payVoForm.handleEdit(this.orderNo)
    },
    /* 修改地址信息 */
    handleChangeOrderAddress(item) {
      this.$refs.ChangeShippingRef.handleEdit(item)
    },
  }
}
</script>

<style lang="less" scoped>
.supplierInfo {
  background: #fff;

  // border: 1px solid #e4e4e4;
  &-title {
    border-bottom: 1px solid #e4e4e4;
    padding: 10px 15px;
    background: #f3f3f3;
    color: #666;
    font-weight: 500;
  }

  &-orderInfo {
    background: #fff;
    padding: 30px 25px;
    border-bottom: 1px solid #e4e4e4;

    .orderNum {
      font-size: 16px;
      color: #999;
      font-weight: 500;
    }

    &-content {
      margin-top: 15px;
      .flexLayout();
    }

    &-left {
      .flexLayout(@direction: column; );
      border-right: 1px solid #e6e6e6;
      padding: 10px 30px 10px 0;

      .label {
        margin-bottom: 8px;
        color: #e12936;
        font-size: 22px;
        font-weight: 500;
      }

      .ant-tag {
        width: 78px;
        text-align: center;
        margin-right: 0;
      }
    }

    &-right {
      flex: 1 0 0;
      .flexLayout(@justifyContent: flex-start; );
      padding: 0 20px;

      .status-step {
        flex: 1;
        .flexLayout(@direction: column; @alignItem: flex-start);
        overflow: hidden;
        position: relative;
        margin-right: 16px;

        .anticon {
          margin-bottom: 8px;
          font-size: 30px;
          margin-left: 8px;
        }

        .label {
          font-size: 16px;
          color: #999;
          font-weight: 500;
        }

        &::after {
          position: absolute;
          top: 16px;
          left: 52px;
          display: block;
          width: 999px;
          height: 1px;
          border-top: 1px dashed #797979;
          content: '';
        }

        &:last-of-type {
          flex: none;

          &::after {
            display: none;
          }
        }
      }
    }
  }

  &-userInfo {
    background: #fff;
    padding: 30px;
    border-bottom: 1px solid #e4e4e4;
    position: relative;
    .flexLayout(@justifyContent: flex-start; @alignItem: flex-start);

    .action_btn {
      position: absolute;
      right: 30px;
      top: 30px;
    }

    .leftBox {
      width: 240px;
      border-right: 1px solid #e6e6e6;
      padding-right: 40px;
      padding-bottom: 20px;
    }

    .title {
      font-size: 16px;
      color: #666;
      font-weight: 500;
    }

    .rightBox {
      flex: 1 0 0;
      padding-left: 40px;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .orderInfo-table {

    &-title,
    &-row {
      .flexLayout();
      text-align: center;
      padding: 20px 15px;
      color: #666;
      position: relative;

      .buttonArea {
        position: absolute;
        bottom: 20px;
        right: 15px;
      }

      .productInfo {
        width: 26%;
      }

      .price {
        width: 16%;
      }

      .subCount {
        width: 20%;
      }

      .payMoney {
        width: 20%;
      }
    }

    &-title {
      background: #eeeeee;
    }

    &-row {
      color: #333;
      border-bottom: 1px solid #e4e4e4;

      .productInfo {
        .flexLayout(@justifyContent: flex-start; @alignItem: flex-start);
        padding-left: 20px;

        img {
          width: 75px;
          height: 75px;
        }

        .info {
          padding-left: 15px;
          .flexLayout(@direction: column; @alignItem: flex-start);

          .subTitle {
            font-size: 16px;
            margin-bottom: 10px;
          }

          .size {
            color: #999;
          }
        }
      }
    }

    .red {
      color: #e12936;
    }

    &-subNum {
      padding: 20px 60px;
      background: #fff;

      ::v-deep .ant-form-item-control {
        text-align: right;
      }

      .lastItem {
        margin-top: 60px;

        ::v-deep .ant-form-item-label label {
          color: #e12936;
        }

        ::v-deep .ant-form-item-children {
          font-size: 22px;
          color: #e12936;
        }
      }
    }
  }
}

.form-title {
  font-size: 15px;
  color: #605f5f;
  margin-bottom: 15px;
  height: 38px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #efefef;
  position: relative;

  &:before {
    background: #FF6E2D;
    display: inline-block;
    content: '';
    width: 5px;
    height: 16px;
    margin-right: 8px;
  }

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000;
    margin-right: 10px;
  }

  .action {
    position: absolute;
    right: 0;
    top: 50%;
    font-size: 14px;
    transform: translateY(-50%);

    img {
      width: 15px;
      margin-right: 4px;
    }
  }
}

.order-info {
  padding: 24px 0;

  &-top {
    padding-bottom: 16px;
    border-bottom: 1px solid #e4e4e5;
    margin-bottom: 25px;

    .title {
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
      font-weight: 500;
      margin-right: 8px;
    }

    .tag {
      width: 52px;
      height: 21px;
      display: inline-block;
      line-height: 21px;
      background: rgba(5, 194, 156, 0.1);
      border-radius: 4px;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      color: #05c29c;
    }
  }
}

.ant-descriptions {
  margin-top: 25px;
  margin-bottom: 32px;

  ::v-deep .ant-descriptions-item-label {
    color: rgba(0, 0, 0, 0.65);
  }

  ::v-deep .ant-descriptions-item-content {
    color: rgba(0, 0, 0, 0.85);
  }
}

::v-deep .ant-table-thead>tr>th {
  background: #efefef;
  border-bottom: none;
}

::v-deep .ant-table {
  color: rgba(0, 0, 0, 0.85);
}

.info span {
  color: rgba(0, 0, 0, 0.85);
}

.productImg {
  width: 60px;
  height: 45px;
  border-radius: 4px;
  object-fit: cover;
  margin-right: 8px;
}

.info-table {
  display: flex;
  align-items: center;

  ::v-deep.ant-input {
    height: 38px;
    margin-left: 8px;
  }
}

.add-btn {
  border-color: #d9d9d9;
  color: #595959;
  font-weight: 500;
  font-size: 16px;
  height: 40px;
  margin-bottom: 24px;
}

.sum-info {
  width: 100%;
  height: 183px;
  padding: 24px 32px;
  background: #fff5ee;
  border: 1px solid #ef8150;
  border-radius: 8px;

  &-top {
    border-bottom: 1px solid #E4E4E6;
    margin-bottom: 16px;

    &-item {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #595959;
      padding-bottom: 17px;

      .price {
        color: #FF6E2D;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

  &-price {
    text-align: right;
    font-weight: 500;
    font-size: 16px;
    color: #131212;

    .price-num {
      color: #FF6E2D;
      font-size: 18px;
    }
  }
}

@import '~@/styles/form.less';
@import '~@/styles/orderDetail.less';
</style>
